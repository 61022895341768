import React, { useState, useEffect, forwardRef, useRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiInput, UiRSelect, BgLoader, UiDateRange, InLoader, UiSelect, PageError, UiActioButton, UiActionDropDown, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { el } from "date-fns/locale";
import queryString from 'query-string';
import { FiUsers } from 'react-icons/fi'
import { AiOutlineExport, AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { BsArrowRightCircle } from 'react-icons/bs'
import { MdVerified } from 'react-icons/md'
import { CSVLink, CSVDownload } from "react-csv";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { rupees } from "../../helper";

export default function TasksReport() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.tasks);
  const setZState = useStore((state) => state.setTasks);
  let componentRef = useRef();


  const parsed = queryString.parse(location.search);

  const [state, setState] = useState({ page: 1 });

  const [pagenum, setPage] = useState(1);

  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});


  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);


  const defaultValues = {
    date: null,
    status: null,
    cust_tag: null,
    task_tag: null,
    service: null,
    is_billed: null,
    is_billable: null,
    is_verified: null,
    task_type: null,
    search: null
  };





  const schema = yup.object().shape(
    {
     // search: yup.string(),

    }
  );

  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);



  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    formState,
    resetField,
    watch,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);

  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/tasks-report?" + u, { replace: true })
    } else {
      navigate("/tasks-report?" + u)
    }


  };

  const exportModal = useModal('export-all');
  const exportAll = (cols) => {
    exportModal.show({ type: '/tasks_report', filter: window.location.search, header: cols, file: 'tasks-report.csv' }).then((res) => {
    });
  };

  var columns = [
    {
      name: 'Task',
      id: 'service',
      selector: row => row.service,
      sortable: true,
      sortField: 'service',
      width: '200px',
      cell: row => (<Link to={`/view-task/${row.id}`}><b>{row.service}</b></Link>),
    },
    {
      name: 'Period',
      id: 'period',
      selector: row => row.period,
      sortable: true,
      width: '150px',
      sortField: 'period',
      cell: row => (<span className="text-truncate" title={row.period}>{row.period}</span>),
    },
    {
      name: 'Date',
      id: 'date',
      selector: row => row.date,
      sortable: true,
      width: '130px',
      sortField: 'date',
    },


    {
      name: 'Client',
      sortable: true,
      id: 'client',
      sortField: 'name',
      width: '200px',
      selector: row => row.name,
      cell: row => (<span className="text-truncate" title={row.name}><Link to={`/view-client/${row.cust_id}`}>{row.name}</Link></span>),
    },
    {
      name: 'Type',
      width: '140px',
      selector: row => row.task_type,

    },
    {
      name: 'Group',
      sortable: true,
      id: 'group_name',
      sortField: 'group_name',
      width: '200px',
      selector: row => row.group_name,
      cell: row => (<span className="text-truncate" title={row.group_name}>{row.group_name}</span>),
    },
    {
      name: 'File No.',
      sortable: true,
      id: 'file',
      sortField: 'file',
      width: '100px',
      selector: row => row.file,

    },

    {
      name: 'Due Date',
      sortable: true,
      id: 'due_date',
      sortField: 'due_date',
      width: '140px',
      selector: row => row.due_date,
      cell: row => (<span>{row.due_date}</span>)
    },

    {
      name: 'Completed On',
      sortable: true,
      id: 'completed_on',
      sortField: 'completed_on',
      width: '140px',
      selector: row => row.completed_on,
      cell: row => (<span>{row.completed_on}</span>)
    },
    {
      name: 'Hours Spent',

      width: '140px',
      selector: row => row.hours,
    },

    {
      name: 'Users',
      width: '200px',
      selector: row => row.users,
      cell: row => (<span className="text-truncate" title={row.users}>{row.users}</span>),
    },

    {
      name: 'Status',
      sortable: true,
      id: 'status',
      width: '130px',
      sortField: 'status',
      selector: row => row.status,

    },
    {
      name: 'Verified',
      width: '100px',
      selector: row => row.verified,

    },
    {
      name: 'Task Tags',
      width: '200px',
      selector: row => row.task_tags,
      cell: row => (<span className="text-truncate" title={row.task_tags}>{row.task_tags}</span>),
    },

    {
      name: 'Is Billable',
      width: '100px',
      selector: row => row.billable,
    },
    {
      name: 'Invoice No.',
      width: '140px',
      selector: row => row.invoice_number,
      cell: row => (<>{row.invoice_id && <Link to={`/view-invoice/${row.invoice_id}`}>{row.invoice_number}</Link>}</>),
    },

    {
      name: 'Billing Amount',
      width: '140px',
      selector: row => row.amount,
      cell: row => (<>{row.amount && rupees(row.amount)}</>),
    },

    {
      name: 'Description',
      selector: row => row.task_description,
      width: '200px',
      cell: row => (<span className="text-truncate" title={row.task_description}>{row.task_description}</span>),
    },

    {
      name: 'Recent Note',
      selector: row => row.note,
    },





  ];

  const headers = [
    { label: "Task", key: "service" },
    { label: "Period", key: "period" },
    { label: "Date", key: "date" },

    { label: "Client", key: "name" },
    { label: "Task Type", key: "task_type" },

    { label: "Group", key: "group_name" },
    { label: "File No.", key: "file" },
    { label: "Due Date", key: "due_date" },
    { label: "Completed On", key: "completed_on" },
    { label: "Hours Spent", key: "hours" },
    { label: "Users", key: "users" },
    { label: "Status", key: "status" },
    { label: "Verified", key: "verified" },
    { label: "Task Tags", key: "task_tags" },
    { label: "Is Billable", key: "billable" },
    { label: "Invoice No.", key: "invoice_number" },
    { label: "Billing Amount", key: "amount" },
    { label: "Description", key: "task_description" },
    { label: "Recent Note", key: "note" },

  ];



  async function fetchData(filter) {

    const { data } = await instance.get(
      "/tasks_report" + filter
    );
    var dataa = data;
    var columns1 = columns;
    var headers1 = headers;
    if (data.cfs && Array.isArray(data.cfs)) {
      data.cfs.forEach(element => {
        columns1.push({ name: element.title, selector: row => row[element.id] })
        headers1.push({ label: element.title, key: element.id })
      });
    }
    dataa.cols = columns1
    dataa.cols1 = headers1
    return dataa;
  }

  const [loadedOptions, setOptions] = useState({});


  const client_w = watch('client');
  useEffect(() => {
    if (client_w == null) {
      setOptions({})
    }
  }, [client_w]);












  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }







  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/tasks-report?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/tasks-report?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/tasks-report?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/tasks-report?' + currentUrlParams.toString())
    }

  };


  // const { data, error, isError, isLoading, isFetching } = useQuery(["tasks", zstate.page, zstate.filter], () => fetchData(zstate.page, zstate.filter), {
  const { data, error, isError, isLoading, isFetching } = useQuery(["tasks_report", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });


  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'GTSU1'], () => fetchData1('GTSU1'), {
    keepPreviousData: true,
  });



  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        date: filter.date,
        is_billed: filter.is_billed,
        is_billable: filter.is_billable,
        task_type: filter.task_type,
        search: filter.search,
        is_verified: filter.is_verified,
        client: filter.client && ({ value: parseInt(filter.client.split('::')[0]), label: filter.client.split('::')[1] }),
        due_date: filter.due_date,
        completed_on: filter.completed_on,
        status: f_data.status_list && filter.status ? f_data.status_list.filter(({ value }) => filter.status.split(',').includes(value)) : null,
        cust_tag: f_data.tags && filter.cust_tag ? f_data.tags.filter(({ value }) => filter.cust_tag.split(',').includes(value.toString())) : null,
        task_tag: f_data.tags && filter.task_tag ? f_data.tags.filter(({ value }) => filter.task_tag.split(',').includes(value.toString())) : null,
        service: f_data.services && filter.service ? f_data.services.filter(({ value }) => filter.service.split(',').includes(value.toString())) : null,
        group: f_data.groups && filter.group ? f_data.groups.filter(({ value }) => filter.group.split(',').includes(value.toString())) : null,
        users: f_data.users && filter.users ? f_data.users.filter(({ value }) => filter.users.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);




  const clearFilter = () => {
    reset(defaultValues)
    navigate("/tasks-report", { replace: true })
  }


  if (isLoading) {
    return <BgLoader />;
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }




  return (
    <>
      <Helmet>
        <title>
          Tasks Report | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Tasks Report" >
            {data.items &&
              <>
                <div className="btn-group1">
                  <a style={{ cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false" className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`}>Export</a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <CSVLink data={data.items} filename={"tasks-report.csv"} headers={data.cols1} className="dropdown-item" >Export view</CSVLink>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => exportAll(data.cols1)}>
                        Export all
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            }

          </PageHeader>


          <form id="tasksfilterfrm" className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
              <div className="p-3 bg-light rounded mb-4 ">

                <div className="row">

                  <div className="col">
                    <label className="form-label">Date</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>



                  <div className="col-md-3">
                    <Controller
                      name="service[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          //defaultValue={null}
                          options={f_data.services}
                          className=""

                          isMulti
                          message={errors.service?.message}
                          label="Service"
                          {...field}
                        />

                      )}
                    />
                  </div>



                  <div className={`col-md-3`}>
                    <Controller
                      name="status[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect

                          options={f_data.status_list}
                          className=""
                          isMulti
                          message={errors.status?.message}
                          label="Status"
                          {...field}
                        />

                      )}
                    />
                  </div>
                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <label className="form-label">Due Date</label>
                    <Controller
                      name="due_date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <label className="form-label">Completed On</label>
                    <Controller
                      name="completed_on"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="task_tag[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.tags}
                          className=""
                          isMulti
                          message={errors.task_tag?.message}
                          label="Task Tag"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="cust_tag[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.tags}
                          className=""
                          isMulti
                          message={errors.cust_tag?.message}
                          label="Client Tag"
                          {...field}
                        />

                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="group[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.groups}
                          className=""
                          isMulti
                          message={errors.group?.message}
                          label="Group"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="users[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.users}
                          className=""
                          isMulti
                          message={errors.users?.message}
                          label="User"
                          {...field}
                        />

                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="client"
                      control={control}
                      render={({ field }) => (

                        <UiClientSelect
                          {...field}

                          className=""
                          message={errors.client?.message}
                          label="Client"
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Is Billed"
                      options={['Yes', 'No']}
                      //defaultValue={val.value}
                      name="is_billed"
                      message={errors.is_billed?.message}
                      {...register('is_billed')}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Is Billable"
                      options={['Yes', 'No']}
                      //defaultValue={val.value}
                      name="is_billable"
                      message={errors.is_billable?.message}
                      {...register('is_billable')}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Is Verified"
                      options={['Yes', 'No']}
                      //defaultValue={val.value}
                      name="is_verified"
                      message={errors.is_verified?.message}
                      {...register('is_verified')}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>

                  <div className={`col-md-2 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <UiSelect
                      label="Task Type"
                      options={['Parent Task', 'Sub Task']}
                      name="task_type"
                      message={errors.task_type?.message}
                      {...register('task_type')}
                    />
                  </div>

                  <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  </div>

                  <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                    <div className="d-flex justify-content-end">
                      {zstate.more_filter == true ? (
                        <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                      ) : (

                        <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                      )}

                      <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                      <UiActioButton type="submit" loading={state["loaderss"]} className="fs-3" tooltip="Apply Filter" title="Go" />
                    </div>

                  </div>
                </div>



              </div>
            )}
          </form>

          <div className="card card-default position-relative">
            {isFetching && (<InLoader />)}
            <div className="card-body fcolumn tablecard">




              <DataTable
                striped={true}
                columns={data.cols}
                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                persistTableHead
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                clearSelectedRows={toggleCleared}
                noDataComponent={<TableZero title="Tasks" />}
                onSort={handleSort}
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                sortServer
                disabled={tableDisable}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
