import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiDateRange, UiActioButton, PageError, UiInput, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { rupees } from "../../helper";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export default function InvoicesReport() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);
  const admin_state = useStore((state) => state.admin);

  const schema = yup.object().shape(
    {

    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    watch,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/invoices-report?" + u, { replace: true })
    } else {
      navigate("/invoices-report?" + u)
    }


  };



  const [loadedOptions, setOptions] = useState({});


  const client_w = watch('client');
  useEffect(() => {
    if (client_w == null) {
      setOptions({})
    }
  }, [client_w]);




  const headers = [
    { label: "Invoice No.", key: "invoice_id" },
    { label: "Date", key: "date" },
    { label: "Services", key: "services" },
    { label: "Client", key: "name" },
    { label: "File No.", key: "file" },
    { label: "Group", key: "group_name" },
    { label: "GSTIN", key: "client_gstin" },
    { label: "Due Date", key: "duedate" },
    { label: "Cancelled", key: "cancelled" },
    { label: "Taxable Amount", key: "taxable_value" },
    { label: "Total Tax", key: "tax" },
    { label: "IGST", key: "igst" },
    { label: "SGST", key: "sgst" },
    { label: "CGST", key: "cgst" },
    { label: "Amount", key: "total" },
    { label: "Created By", key: "creator_name" },

  ];

  const headers1 = [
    { label: "Invoice No.", key: "invoice_id" },
    { label: "Date", key: "date" },
    { label: "Services", key: "services" },
    { label: "Client", key: "name" },
    { label: "File No.", key: "file" },
    { label: "Group", key: "group_name" },
    { label: "GSTIN", key: "client_gstin" },
    { label: "Due Date", key: "duedate" },
    { label: "Cancelled", key: "cancelled" },
    { label: "Taxable Amount", key: "taxable_value" },
    { label: "Total Tax", key: "tax" },
    { label: "IGST", key: "igst" },
    { label: "SGST", key: "sgst" },
    { label: "CGST", key: "cgst" },
    { label: "Amount", key: "total" },
    { label: "Received Amount", key: "mapped_amount" },
    { label: "Due Amount", key: "due" },
    { label: "Receipt Numbers", key: "mapped_receipts" },
    { label: "Status", key: "status" },
    { label: "Created By", key: "creator_name" },

  ];




  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});

  const handleRowSelected = React.useCallback(state => {
    setState1({ selected: state.selectedRows })
  }, []);


  const exportModal = useModal('export-all');
  const exportAll = () => {
    exportModal.show({ type: '/invoices_report', filter: window.location.search, header: admin_state.user.bill_map == 1 ? headers1 : headers, file: 'invoices-report.csv' }).then((res) => {
    });
  };


  async function fetchData(filter) {
    const { data } = await instance.get(
      "/invoices_report" + filter
    );

    var dataa =data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    client: null,
    date: null,
    group: null,
    entity: null,
    status: null,
    user: null
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/invoices-report", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [
    {
      name: 'Invoice No.',
      selector: row => row.invoice_id,
      cell: row => (<Link to={`/view-invoice/${row.id}`}><b>{row.invoice_id}</b></Link>),
      sortable: true,
      sortField: 'invoice_id',
      id: 'invoice_id',
      width: '140px',
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      sortField: 'date',
      id: 'date',
      width: '120px',
    },
    {
      name: 'Billing Entity',
      selector: row => row.entity,
      sortable: true,
      sortField: 'entity',
      id: 'entity',
      width: '160px',
      cell: row => (<span className="text-truncate" title={row.entity}>{row.entity}</span>),
    },
    {
      name: 'Services',
      selector: row => row.services,
      width: '200px',
      cell: row => (<span className="text-truncate" title={row.services}>{row.services}</span>),
    },



    {
      name: 'Client ',
      selector: row => row.name,
      sortable: true,
      sortField: 'name',
      id: 'name',
      width: '180px',
      cell: row => (<span className="text-truncate" title={row.name}><Link to={`/view-client/${row.cust_id}`}>{row.name}</Link></span>),
    },
    {
      name: 'File No. ',
      selector: row => row.file,
      width: '120px',
      cell: row => (<span className="text-truncate" title={row.file}>{row.file}</span>),
    },
    {
      name: 'Group',
      selector: row => row.group_name,
      width: '160px',
      cell: row => (<span className="text-truncate" title={row.group_name}>{row.group_name}</span>),
    },
    {
      name: 'GSTIN',
      selector: row => row.client_gstin,
      width: '160px',
    },

    {
      name: 'Due Date',
      sortable: true,
      sortField: 'duedate',
      id: 'duedate',
      selector: row => row.duedate,
      width: '120px',
    },
    {
      name: 'Cancelled',
      selector: row => row.cancelled,
      width: '100px',
    },
    {
      name: 'Taxable Amount',
      selector: row => row.taxable_value,
      width: '140px',
      cell: row => (<span>{rupees(row.taxable_value)}</span>)
    },
    {
      name: 'Total Tax',
      selector: row => row.tax,
      width: '110px',
      cell: row => (<span>{rupees(row.tax)}</span>)
    },
    {
      name: 'IGST',
      selector: row => row.igst,
      width: '110px',
      cell: row => (<span>{rupees(row.igst)}</span>)
    },
    {
      name: 'SGST',
      selector: row => row.sgst,
      width: '110px',
      cell: row => (<span>{rupees(row.sgst)}</span>)
    },
    {
      name: 'CGST',
      selector: row => row.cgst,
      width: '110px',
      cell: row => (<span>{rupees(row.cgst)}</span>)  
    },

    {
      name: 'Total Amount',
      sortable: true,
      sortField: 'total',
      id: 'total',
      selector: row => row.total,
      width: '140px',
      cell: row => (<span>{rupees(row.total)}</span>)
    },


    {
      name: 'Received Amount',
      omit:admin_state.user.bill_map == 1 ? false : true,
      selector: row => row.mapped_amount,
      cell: row => (<span>{rupees(row.mapped_amount)}</span>)
    },

    {
      name: 'Due Amount',

      omit:admin_state.user.bill_map == 1 ? false : true,
   
      selector: row => row.due,
      cell: row => (<span>{rupees(row.due)}</span>)
    },

    {
      name: 'Receipt Numbers',
      width: '170px',
      omit:admin_state.user.bill_map == 1 ? false : true,
   
      selector: row => row.mapped_receipts,
      cell: row => (<span>{row.mapped_receipts}</span>)
    },

    {
      name: 'Status',
      sortable: true,
      sortField: 'status',
      omit:admin_state.user.bill_map == 1 ? false : true,
      id:'status',
      selector: row => row.status,
    },
    {
      name: 'Created By',
      selector: row => row.creator_name,
      width: '160px',
      cell: row => (<span className="text-truncate" title={row.creator_name}>{row.creator_name}</span>),
    },
  ];






  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }
  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/invoices-report?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/invoices-report?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/invoices-report?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/invoices-report?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["invoices_report", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });

  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'MGBU'], () => fetchData1('MGBU'), {
    keepPreviousData: true,
  });

  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        search: filter.search,
        date: filter.date,
        client: filter.client && ({ value: parseInt(filter.client.split('::')[0]), label: filter.client.split('::')[1] }),
        group: f_data.groups && filter.group ? f_data.groups.filter(({ value }) => filter.group.split(',').includes(value.toString())) : null,
        entity: f_data.billing_entities && filter.entity ? f_data.billing_entities.filter(({ value }) => filter.entity.split(',').includes(value.toString())) : null,
        status: f_data.trans_status && filter.status ? f_data.trans_status.filter(({ value }) => filter.status.split(',').includes(value.toString())) : null,
        user: f_data.users && filter.user ? f_data.users.filter(({ value }) => filter.user.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Invoices Report | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Invoices Report" >
            {data.items &&
              <div className="btn-group1">
                <a style={{ cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false" className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`}>Export</a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <CSVLink data={data.items} filename={"invoices-report.csv"} headers={admin_state.user.bill_map == 1 ? headers1 : headers} className="dropdown-item" >Export view</CSVLink>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={() => exportAll()}>
                      Export all
                    </button>
                  </li>
                </ul>
              </div>

            }

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
              <div className="p-3 bg-light rounded mb-4 ">

                <div className="row">


                  <div className="col">
                    <label className="form-label">Date</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>


                  <div className={`col-md-3`}>
                    <Controller
                      name="client"
                      control={control}
                      render={({ field }) => (

                        <UiClientSelect
                          {...field}
        
                          className=""
                          message={errors.client?.message}
                          label="Client"
                        />

                      )}
                    />
                  </div>




                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="group[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.groups}
                          className=""
                          isMulti
                          message={errors.group?.message}
                          label="Group"
                          {...field}
                        />

                      )}
                    />
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <Controller
                    name="entity[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        defaultValue={null}
                        options={f_data.billing_entities}
                        className=""
                        isMulti
                        hideSelectedOptions= {false}
                        message={errors.entity?.message}
                        label="Billing Entity"
                        {...field}
                      />

                    )}
                  />
                </div>


                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    {admin_state.user.bill_map == 1 &&
                    <Controller
                      name="status[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.trans_status}
                          className=""
                          isMulti
                          hideSelectedOptions={false}
                          message={errors.status?.message}
                          label="Status"
                          {...field}
                        />

                      )}
                    />
                      }
                  </div>

                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="user[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.users}
                          className=""
                          isMulti

                          label="Created By"
                          {...field}
                        />

                      )}
                    />
                  </div>

                  <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}></div>


                  <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                    <div className="d-flex justify-content-end">
                      {zstate.more_filter == true ? (
                        <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                      ) : (

                        <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                      )}

                      <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                      <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                    </div>

                  </div>
                </div>



              </div>
            )}
          </form>


          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="p-3 rounded bg-light border-dashed h-100 d-flex align-items-end">
                <div>
                  <span className="fs-3 lh-1">{rupees(data.stats.total)}</span>
                  <span className="mt-2 fw-semibold d-block text-muted">Total Amount</span>
                </div>
              </div>
            </div>
          </div>

          
          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body fcolumn tablecard">

              <DataTable
                columns={columns}
                striped={true}
                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                clearSelectedRows={toggleCleared}
                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Invoices" />}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
