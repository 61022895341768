import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiSelect, PageHeader, UiInput, UiButton, UiTextArea, UiDatePicker, UiRSelect, PageError, BgLoader, UiClientSelect } from "../../ui";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import addDays from 'date-fns/addDays'
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import queryString from 'query-string';
import { Helmet } from "react-helmet";


export default function Quotation() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});

  const [bg_loader, setBGLoader] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const schema = yup.object().shape({


    date: yup.string().nullable().required("Select quotation date"),
    quote_id: yup.string().nullable().required("Enter quotation number").max(15, 'Maximum character limit is 15'),
    client: yup.object().nullable().required("Select client"),
    entity: yup.object().nullable().required("Select billing entity"),


    task: yup.array().of(
      yup.object().shape({

        description: yup.string().nullable().max(200, 'Maximum character limit is 200'),
        service: yup.string().nullable().required('Select service'),
        amount: yup.number().typeError('Amount must be a number').nullable().min(0, 'Negative value not allowed').required('Amount is required')


      })
    ),



  });




  var handleSave = (e) => {
    setState({ ...state, loader: true });


    instance({
      method: "post",
      url: "/update_quote",
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['quotations'])
          toast(response.data.msg, { type: "success" });
          if (response.data.id) {
            navigate("/view-quotation/" + response.data.id)
          }

        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };

  const { fields, append, prepend, remove, swap, move, insert, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "task", // unique name for your Field Array
  });




  const defaultValues = {
    date: null,
    client: '',
    quote_id: '',
    term: '',
    duedate: null,


  };



  const fetchData = () => {
    instance
      .get("/get_quote?id=" + id)
      .then(function (response) {
        setState({ ...state,total:null, ...response.data });
        if (response.data.status == 'success') {

          if (parsed.client) {
            reset({ ...response.data.quote, client: { value: parsed.client.split('::')[0], label: parsed.client.split('::')[1] } })
          } else {
            reset(response.data.quote)
          }

        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();

  }, [location]);

  useEffect(() => {
    return () => {
      //reset(defaultValues)
    }
  }, [location]);


  useEffect(() => {
    reset(state.client)
  }, [state.client]);

  const [loadedOptions, setOptions] = useState({});
 

  const client_w = watch('client');
  useEffect(() => {
    if (client_w == null) {
      setOptions({})
    }
  }, [client_w]);





  const updateAmount = (id, amt) => {

    var arr = fields.slice();
    const crnt = arr.filter(x => x.id == id)[0];

    const gst = parseInt(crnt.gst);

    crnt.amount = amt;

    var amt_final = parseFloat(amt - amt * (gst / (100 + gst))).toFixed(2);
    crnt.price = isNaN(amt_final) ? '' : amt_final

    var arr1 = arr;
    arr1 = arr1.filter(x => x.selected == 1);
    //resetField("task", { defaultValue: arr })
    reset({ ...getValues(), 'task': arr })
    var sum = 0;


    for (let i = 0; i < arr.length; i++) {
      var amtt = parseFloat(arr[i]['amount']);
      if (amtt > 0) {
        sum += amtt;
      }

    }
    setState({ ...state, tasks: arr, total: sum })
  }

  const updatePrice = (id, amt) => {

      var arr = fields.slice();
    
   

    const crnt = arr.filter(x => x.id == id)[0];
    const gst = parseInt(crnt.gst);
    crnt.price = amt;
    var amt_final = amt == 0 ? 0 : parseFloat(parseFloat(amt) + parseFloat(amt * gst / 100)).toFixed(2)
    crnt.amount = isNaN(amt_final) ? '' : amt_final
    //Math.round(parseInt(amt) + parseInt(amt * gst / 100));
    var arr1 = arr;
    arr1 = arr1.filter(x => x.selected == 1);
    //resetField("task", { defaultValue: arr })
    //remove()
    reset({ ...getValues(), 'task': arr })
    var sum = 0;


    for (let i = 0; i < arr.length; i++) {
      var amtt = parseFloat(arr[i]['amount']);
      if (amtt > 0) {
        sum += amtt;
      }

    }

    setState({ ...state, total: sum })
  }
  const updateTax = (id, amt) => {
    var arr = fields.slice();
    const crnt = arr.filter(x => x.id == id)[0];
    arr.filter(x => x.id == id)[0].gst = amt;
    setState({ ...state, tasks: arr })
    updatePrice(id, crnt.price)
  }
  const updateService = (id, sid) => {
    
    var service_arr = state.services.slice();
    const service = service_arr.filter(x => x.value == sid)[0];
    var arr = fields.slice();
    const crnt = arr.filter(x => x.id == id)[0];
    arr.filter(x => x.id == id)[0].gst = service.gst;
    arr.filter(x => x.id == id)[0].service = service.value;
    // arr.filter(x => x.id == id)[0].price = service.price;
    reset({ ...getValues(), 'task': arr })
    //resetField("task", { defaultValue: arr })
    updatePrice(id, service.price)
  }




  const entityWatch = watch("entity");



  useEffect(() => {
    if (entityWatch && id == 'new' && state.quote.quote_id !== entityWatch.quote_id) {
    
      setState({ ...state, quote: { ...state.quote, quote_id: entityWatch.quote_id }, is_gst: entityWatch.is_gst })
      resetField('quote_id', { defaultValue: entityWatch.quote_id })
      if (entityWatch.is_default == 1) {

      } else {
        //loadEntity(entityWatch.value)
      }
    }
  }, [entityWatch]);


  const removeEntry = (id) => {
    var arr = fields.slice();



    var sum = 0;
    var itms = [];

    for (let i = 0; i < arr.length; i++) {
      if (i != id) {
        var amtt = parseFloat(arr[i]['amount']);
        if (amtt > 0) {
          sum += amtt;
        }
        itms.push(arr[i]);
      }
    }
    reset({ ...getValues(), 'task': itms })
    setState({ ...state, total: sum })
  }


  useEffect(() => {
    if (state.tload) {
      resetField("task", { defaultValue: state.tasks })
    }
  }, [state.tload]);




  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Quotation | Practive
        </title>
      </Helmet>
      <div>
        {state.status ? (
          <div>
            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Quotation`} parent="Quotations" link="/quotations">

            </PageHeader>



            <form id="quotefrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

              <div className="card card-default mb-4">

                <div className="card-body">

                  <div className="row">

                    <div className="col-md-6" >
                      <div style={{ maxWidth: '400px' }}>

                        <Controller
                          name="entity"
                          control={control}

                          render={({ field }) => (

                            <UiRSelect

                              required={true}
                              {...field}
                              options={state.entities}
                              className=""
                              isDisabled={id == 'new' ? false : true}
                              message={errors.entity?.message}
                              label="Billing Entity"

                            />

                          )}
                        />


                        <Controller
                          name="client"
                          control={control}

                          render={({ field }) => (

                            <UiClientSelect
                              {...field}
                              required={true}
                         
                              message={errors.client?.message}
                              label="Client"

                            />

                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Controller

                        name="date"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            lcol={4}
                            icol={8}
                            required={true}
                            label="Date"
                            onChange={onChange}
                            // maxDate={parse(duedateWatch, "dd-MM-yyyy", new Date())}
                            default_val={value}
                            message={errors.date?.message}
                            dateFormat="dd-MM-yyyy"
                            ref={ref}
                            name={name}
                          />
                        )}
                      />
                      <UiInput
                        lcol={4}
                        icol={8}
                        label="Quotation No."
                        name="quote_id"
                        required={true}
                        {...register("quote_id")}
                        message={errors.quote_id?.message}
                        disabled={state.quote.quote_id ? true : false}
                      />

                    </div>
                  </div>




                  <input type="hidden" defaultValue={id} name="id" {...register("id")} />


                  <h2 className="fs-4">Items</h2>
                  <div className="table-responsive bg-light p-2 rounded">
                    <table className="table align-middle">
                      <thead>
                        <tr className="text-secondary text-uppercase fw-semibold">
                          <th style={{minWidth:'200px'}}>Service</th>
                          {state.is_gst == 1 && <>
                            <th width="200px" style={{minWidth:'150px'}}>Amount (₹)</th>
                            <th width="100px" className="text-center" style={{minWidth:'100px'}}>GST (%)</th>
                          </>}
                          <th width="200px" style={{minWidth:'200px'}}>Total Amount (₹)</th>
                        </tr>
                      </thead>



                      <tbody>






                        {fields && fields.length > 0 && fields.map((val, i) => (
                          <tr key={i}>
                            <td>
                              <div className="d-flex justify-content-between">


                                <UiSelect
                                  name={`task[${i}].service`}
                                  required={true}
                                  options={state.services}
                                  className="form-select-sm"
                                  parentClass="mb-1"
                                  message={errors.task?.[i]?.service?.message}
                                  noerror={true}
                                  placeholder="Select Service"

                                  {...register(`task.[${i}].service`, {onChange: (e) => {updateService(val.id, e.target.value)}})}
                                  //onChange={(e) => updateService(val.id, e.target.value)}
                                />


                              </div>
                              <UiInput name={`task[${i}].description`} {...register(`task.[${i}].description`)} parentClass="mb-0" className="form-control-sm" placeholder="Description" message={errors.task?.[i]?.description?.message} />
                            </td>

                            {state.is_gst == 1 && <>
                              <td>
                                <UiInput icon="inr" name={`task[${i}].price`} {...register(`task.[${i}].price`)} parentClass="mb-0" message={errors.task?.[i]?.price?.message} onChange={(e) => updatePrice(val.id, e.target.value)} />
                              </td>
                              <td>
                                <UiInput type="number" icon="percent" name={`task[${i}].gst`} {...register(`task.[${i}].gst`)} parentClass="mb-0" message={errors.task?.[i]?.gst?.message} onChange={(e) => updateTax(val.id, e.target.value)} />
                              </td>
                            </>}
                            <td >
                              <span className="d-flex">
                              

                                <UiInput
                                  icon="inr"
                                  name={`task[${i}].amount`}
                                  noerror={true}
                                  {...register(`task.[${i}].amount`)}
                                  parentClass="mb-0"
                                  message={errors.task?.[i]?.amount?.message}
                                  onChange={(e) => updateAmount(val.id, e.target.value)}
                                />
                                <UiButton type="button" className="btn btn-outline-danger ms-3" onClick={() => removeEntry(i)} title="" icon="delete"></UiButton>
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr className="text-center">
                          <td colSpan={state.is_gst == 1 ? 4 : 2}>
                            <UiButton type="button" className="btn btn-outline-success" onClick={() => append()} title="Add Item" icon="add"> </UiButton>
                          </td>
                        </tr>
                      </tbody>



                      <tfoot>
                        <tr>
                          <td colSpan={state.is_gst == 1 ? 3 : 1} className="fw-bold text-end">
                            Total Amount
                          </td>
                          <td className="fw-bold">
                            {rupees(state.total)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <UiTextArea
                        label="Terms & Conditions"
                        name="tnc"
                        message={errors.tnc?.message}
                        {...register("tnc")}
                      />
                    </div>
                  </div>


                </div>
              </div>




              <UiButton loading={state["loader"]} title="Save" disabled={state.total < 1 ? "1" : '0'} />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
